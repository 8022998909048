<template>
  <MyLink v-bind="linkData" :link-text />
</template>

<script setup>
const i18n = useI18n();
const props = defineProps({
  button: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: null,
  },
  noIcon: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "general",
    validator: (value) => ["general", "analytics-demo"].includes(value),
  },
});
const linkData = ref(null);
const websiteConfig = useState("config");
const linkText = computed(
  () =>
    props.label ||
    websiteConfig.value.data.story.content.sales_contact_link_text,
);
function initLinkData() {
  const button = useSalesContactButton({
    ...props,
    label: props.label || linkText.value,
  });
  linkData.value = unref(button.linkData);
}

i18n.onLanguageSwitched = () => {
  initLinkData();
};

onMounted(() => {
  initLinkData();
});
</script>
